import {
    Box,
    Card,
    CardContent,
    Grid2,
    Typography,
    useMediaQuery,
  } from "@mui/material";
  import React from "react";
  import theme from "../../theme";
  
  export default function Step5(props) {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
    return (
      <Box
        sx={{
          border: "1px solid #ECEFF3",
          borderRadius: "16px",
          boxShadow: "0px 2px 8px -1px #0D0D120A",
          padding: isMobile?"16px 20px":"32px 40px",
          display:"flex",
          flexDirection:'column',
          justifyContent:"center",
          alignItems:"center"
        }}
      >
        <Box
        component={"img"}
        src="/images/icons/estimateCompleted.png"
        />
        <Typography
        variant="body1"
        fontWeight={400}
        lineHeight={'30px'}
        sx={{
            textAlign:"center",
        }}
        mt={'40px'}
        width={"40%"}
        >
        "Thanks for reaching out! Your request just zoomed over to our cleaning pros. We'll get back to you faster than a mop on a mission!"
        </Typography>
      </Box>
    );
  }
  