import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import HorizontalLinearStepper from "../Components/reusable/HorizontalLinearStepper";
import Step1 from "../Components/quoteSteps/Step1";
import Step2 from "../Components/quoteSteps/Step2";
import Step3 from "../Components/quoteSteps/Step3";
import Step4 from "../Components/quoteSteps/Step4";
import Step5 from "../Components/quoteSteps/Step5";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const steps = [
  "Select Service",
  "Personal Information",
  // "Select Services",
  "Book a Slot",
  "Finish",
];

export default function GetQuote() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [data, setData] = React.useState({
    selectedServices: "",
    date: new Date(),
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const [valid, setValid] = React.useState(false);

  const checkConditions = () => {
    if (activeStep == 0 && data?.type && data?.type != "") {
      setValid(true);
    } else if (
      activeStep == 1 &&
      data?.firstName &&
      data?.firstName != "" &&
      data?.lastName &&
      data?.lastName != "" &&
      data?.email &&
      data?.email != "" &&
      data?.phone &&
      data?.phone != "" &&
      data?.zipCode &&
      data?.zipCode != "" &&
      data?.city &&
      data?.city != "" &&
      data?.address &&
      data?.address != ""
    ) {
      setValid(true);
    } else if (activeStep == 2 && data?.selectedServices != "") {
      setValid(true);
    } else if (
      activeStep == 3 &&
      data?.date &&
      data?.date != "" &&
      data?.time &&
      data?.time != ""
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  useEffect(() => {
    checkConditions();
  }, [data, activeStep]);

  const handleSendMail = async () => {
    try {
      setIsLoading(true);
      // await fetch("http://wonderlandclean.com:3000/send-email", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(data),
      // });

      await axios.post('https://api.wonderlandclean.com/send-email', data)

      setIsLoading(false);
      setActiveStep(
        activeStep < steps?.length - 1 ? activeStep + 1 : activeStep
      );
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 200);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box mb={"20vh"}>
      <ResponsiveAppBar />
      <Container>
        <Box mt={"20vh"}>
          <Typography variant="h4" fontWeight={600} color={"#0D0D12"}>
            Get an Estimate
          </Typography>
          <Typography
            variant="body1"
            fontWeight={400}
            color={"#00262E"}
            mt={"12px"}
          >
            Whether you need a one-time deep clean, regular janitorial services,
            or a specialized cleaning solution, we’ve got you covered. Fill out
            the form below to receive a tailored quote based on your unique
            requirements.
          </Typography>
        </Box>
        <Card
          sx={{
            mt: "50px",
            border: "1px solid #191919",
            borderRadius: "16px",
            padding: "40px",
          }}
        >
          <CardContent
            sx={{
              margin: 0,
              padding: 0,
            }}
          >
            <Box sx={{}}>
              <HorizontalLinearStepper steps={steps} activeStep={activeStep} />
              <Box mt={"40px"}>
                {activeStep == 0 ? (
                  <Step1 data={data} setData={setData} />
                ) : activeStep == 1 ? (
                  <Step2 data={data} setData={setData} />
                ) : activeStep == 2 ? (
                  <Step4 data={data} setData={setData} />
                ) : (
                  <Step5 data={data} setData={setData} />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "32px",
                  justifyContent:
                    activeStep === 0
                      ? "flex-end"
                      : activeStep === steps?.length - 1
                      ? "center"
                      : "space-between",
                }}
              >
                {activeStep != 0 && activeStep != steps?.length - 1 && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "transparent",
                      border: "1px solid #000000",
                      borderRadius: "38px",
                      padding: "12px 76px",
                    }}
                    onClick={() => {
                      if (activeStep == 1) {
                        setData({});
                      }
                      setActiveStep(activeStep > 0 ? activeStep - 1 : 0);
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      color={"#000000"}
                      lineHeight={"27px"}
                      sx={{
                        textTransform: "none",
                      }}
                    >
                      Go Back
                    </Typography>
                  </Button>
                )}
                {activeStep != steps?.length - 1 && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#32BEAF",
                      borderRadius: "38px",
                      padding: "12px 76px",
                    }}
                    disabled={!valid}
                    onClick={() => {
                      if (activeStep == 2) {
                        handleSendMail();
                      } else {
                        setActiveStep(
                          activeStep < steps?.length - 1
                            ? activeStep + 1
                            : activeStep
                        );
                        setTimeout(() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }, 200);
                      }
                    }}
                  >
                    {isLoading?
                    <CircularProgress color="inherit" size={20} />
                    :
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      color={"#E1FAFF"}
                      lineHeight={"27px"}
                      sx={{
                        textTransform: "none",
                      }}
                    >
                      Next
                    </Typography>
                                        
                  }
                  </Button>
                )}
                {activeStep == steps?.length - 1 && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "transparent",
                      border: "1px solid #000000",
                      borderRadius: "38px",
                      padding: "12px 76px",
                    }}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      color={"#000000"}
                      lineHeight={"27px"}
                      sx={{
                        textTransform: "none",
                      }}
                    >
                      Go Home
                    </Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
