import {
  Box,
  Card,
  CardContent,
  Grid2,
  Radio,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import theme from "../../theme";
import {
  commercialFeaturedImages,
  ResidentialFeaturedImages,
} from "../../Data/FeaturedServices";

export default function Step3(props) {
  const [services, setServices] = React.useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setServices(
      props?.data?.type == "commercial"
        ? commercialFeaturedImages
        :props?.data?.type == "residential"? ResidentialFeaturedImages:[]
    );
  }, [props?.data?.type]);

  return (
    props?.data?.type && props?.data?.type!="" && 
    <Box
      sx={{
        border: "1px solid #ECEFF3",
        borderRadius: "16px",
        boxShadow: "0px 2px 8px -1px #0D0D120A",
        padding: isMobile ? "16px 20px" : "32px 40px",
      }}
    >
      <Typography
        variant="body1"
        color={"#36394A"}
        fontWeight={600}
        lineHeight={"24px"}
      >
        Choose your service
      </Typography>
      <Grid2 container mt={"32px"} spacing={6}>
        {services.map((item, index) => {
          return (
            <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
              <Card
                sx={{
                  border: "1px solid #E9E9E9",
                  borderRadius: "16px",
                  boxShadow: "0px 2px 8px -1px #0D0D120A",
                  borderColor: props?.data?.selectedServices == item.title
                    ? "#32BEAF"
                    : "#E9E9E9",
                  cursor: "pointer",
                  "&:hover": {
                    border: "1px solid #32BEAF",
                  },
                }}
                onClick={() => {
                  props?.setData({
                    ...props?.data,
                    selectedServices: item.title
                    // props?.data?.selectedServices?.includes(
                    //   item.title
                    // )
                    //   ? props?.data?.selectedServices?.filter(
                    //       (service) => service != item.title
                    //     )
                    //   : [...props?.data?.selectedServices, item.title],
                  });
                }}
              >
                <CardContent>
                  <Box
                    component={"img"}
                    src={item.image}
                    sx={{
                      width: "100%",
                      height: "200px", // Ensure the image fits within the container
                      objectFit: "cover", // Prevent image distortion
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      marginBottom: "16px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <Radio
                      checked={props?.data?.selectedServices ==item.title}
                      sx={{
                        "&.Mui-checked": {
                          color: "#32BEAF",
                        },
                      }}
                    />
                    <Box>
                      <Typography
                        variant="body1"
                        color={"#36394A"}
                        fontWeight={600}
                        lineHeight={"24px"}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={"#36394A"}
                        fontWeight={400}
                        lineHeight={"22px"}
                      >
                        {item.content}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid2>
          );
        })}
        {/* <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          </Grid2> */}
      </Grid2>
    </Box>
  );
}
