import {
  Box,
  Card,
  CardContent,
  FormLabel,
  Grid2,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import theme from "../../theme";

export default function Step2(props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [errors,setError] = React.useState({})

  const checkValidation = () => {
    let errors = {};
    if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(props?.data?.email))
    {
      errors.email = {msg:"Enter Valid Email",error:true}
    }
    setError(errors);
  }

  const renderTextField = (label, placeholder, key) => {
    return (
      <Box>
        <Typography
          variant="body2"
          fontWeight={500}
          lineHeight={"22px"}
          mb={0.5}
        >
          {label}
        </Typography>
        <TextField
          error={errors[key]?.error}
          helperText={errors[key]?.msg}
          name={key}
          id="outlined-basic"
          placeholder={placeholder}
          value={props?.data[key]}
          onBlur={()=>{
            if(label == 'Email')
            checkValidation(key,props?.data[key]);
          }}
          onChange={(e) => {
            props?.setData({
              ...props?.data,
              [key]: e.target.value,
            });
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px", // Custom border radius
              height: "48px",
            },
          }}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        border: "1px solid #ECEFF3",
        borderRadius: "16px",
        boxShadow: "0px 2px 8px -1px #0D0D120A",
        padding: isMobile ? "16px 20px" : "32px 40px",
      }}
    >
      <Grid2 container rowSpacing={4} columnSpacing={4}>
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          {renderTextField("First Name", "John", "firstName")}
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          {renderTextField("Last Name", "Doe", "lastName")}
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          {renderTextField("Email", "johndoe@gmail.com", "email")}
        </Grid2>
         <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          {renderTextField("Phone", "(555) 555-1234", "phone")}
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          {renderTextField("Zip Code", "1234", "zipCode")}
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          {renderTextField("City", "New York", "city")}
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 12, md: 12 }}>
          {renderTextField("Address", "121, Street 22", "address")}
        </Grid2>
      </Grid2>
    </Box>
  );
}
