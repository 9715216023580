import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { StepConnector, Typography } from "@mui/material";
import styled from "@emotion/styled";

export default function HorizontalLinearStepper(props) {
  // Custom Connector
  const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    "&.MuiStepConnector-root": {
      "& .MuiStepConnector-line": {
        borderColor: "#D4D4D8", // Default color
        borderWidth: "2.2px",
        margin: "0px -4px 0px -4px",
      },
    },
    "&.Mui-active .MuiStepConnector-line": {
      borderColor: "#40C4AA", // Color for active step
    },
    "&.Mui-completed .MuiStepConnector-line": {
      borderColor: "#40C4AA", // Color for completed steps
    },
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={props?.activeStep}
        alternativeLabel
        connector={<CustomStepConnector />}
      >
        {props?.steps.map((label, index) => (
          <Step
            key={label}
            sx={{
              "& .MuiStepIcon-root": {
                color: "gray", // Active step has green color
                fontSize: "30px",
                fontWeight: "500",
                "&.Mui-active": {
                  color: "#40C4AA",
                  fontSize: "30px",
                  fontWeight: "bold",
                },
                "&.Mui-completed": {
                  color: "#40C4AA", // Purple for completed steps
                },
              },
            }}
          >
            <StepLabel>
              <Typography
                variant="body2"
                sx={{ color: props?.activeStep === index ? "#1D2453" : "#C8C8D0" }}
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
