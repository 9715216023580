import {
  Box,
  Card,
  CardContent,
  Grid2,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import theme from "../../theme";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export default function Step4(props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const timeSlots = [
    "08:00AM - 09:00AM",
    "09:00 AM - 10:00 AM",
    "10:00 AM - 11:00 AM",
    "11:00 AM - 12:00 PM",
  ];

  return (
    <Box
      sx={{
        border: "1px solid #ECEFF3",
        borderRadius: "16px",
        boxShadow: "0px 2px 8px -1px #0D0D120A",
        padding: isMobile ? "16px 20px" : "32px 40px",
      }}
    >
      <Typography
        variant="body1"
        color={"#36394A"}
        fontWeight={600}
        lineHeight={"24px"}
      >
        Choose a Date and Timeslot
      </Typography>
      <Grid2 container mt={"32px"} spacing={6}>
        <Grid2 size={{ xs: 12, sm: 12, md: 5 }}>
          <Calendar
            style={{ width: "100%" }}
            onChange={(date) => {
              props?.setData({
                ...props?.data,
                date: date,
              });
            }}
            value={props?.data?.date}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 12, md: 7 }}>
            <Grid2 container spacing={2}>
                {timeSlots.map((item,index) => {
                    return (
                        <Grid2 size={{ xs: 12, sm: 12, md: 6 }} key={index}>
                          <Box
                          sx={{
                            padding:  "12px 30px",
                            border: "1px solid #E9E9E9",
                            borderRadius: "16px",
                            boxShadow: "0px 2px 8px -1px #0D0D120A",
                            backgroundColor:
                              props?.data?.time === item ? "#32BEAF" : "#FFFFFF",
                            cursor: "pointer",
                            "&:hover": {
                              borderColor: "#32BEAF",
                            },
                          }}
                          onClick={() => {
                            props?.setData({
                              ...props?.data,
                              time: item,
                            });
                          }}
                          >
                            <Typography
                              variant="body1"
                              color={props?.data?.time === item?"#FFFFFF":"#A4ACB9"}
                              fontWeight={400}
                              lineHeight={"26px"}
                            >
                              {item}
                            </Typography>
                          </Box>
                        </Grid2>
                    );
                })}
                </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
}
