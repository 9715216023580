import {
  Box,
  Card,
  CardContent,
  Grid2,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import theme from "../../theme";
import Step3 from './Step3'

export default function Step1(props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        border: "1px solid #ECEFF3",
        borderRadius: "16px",
        boxShadow: "0px 2px 8px -1px #0D0D120A",
        padding: isMobile?"16px 20px":"32px 40px",
      }}
    >
      <Typography
        variant="body1"
        color={"#36394A"}
        fontWeight={600}
        lineHeight={"24px"}
      >
        Choose your service type
      </Typography>
      <Grid2 container mt={"32px"} spacing={6}>
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          <Box
            sx={{
              padding: isMobile ? "20px" : "40px",
              border: "1px solid #E9E9E9",
              borderRadius: "16px",
              boxShadow: "0px 2px 8px -1px #0D0D120A",
              borderColor:
                props?.data?.type === "commercial" ? "#32BEAF" : "#E9E9E9",
                cursor:'pointer',
                "&:hover": {
                  border: "1px solid #32BEAF",
                },
            }}
            onClick={() => {
              props?.setData({ ...props?.data, type: "commercial" });
              window.scrollTo({top:document.getElementById('service-section').offsetTop-120,behavior:'smooth'});
            }}
          >
            <Grid2
              container
              spacing={2}
              sx={{
                alignItems: "center",
              }}
            >
              <Grid2 size={{ xs: 12, sm: 12, md: 3 }}>
                <Box
                  component={"img"}
                  src={
                    props?.data?.type === "commercial"
                      ? "./images/icons/selectedCommercial.png"
                      : "./images/icons/unselectedCommercial.png"
                  }
                  mr={"32px"}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 12, md: 9 }}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={"#36394A"}
                      fontWeight={600}
                      lineHeight={"24px"}
                    >
                      Commercial Services
                    </Typography>
                    <Box
                      component={"img"}
                      src="./images/icons/info.png"
                      ml={"8px"}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    color={"#666D80"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    mt={"12px"}
                  >
                    Expert commercial cleaning for offices, medical facilities,
                    schools, churches, and more. We tailor every plan to your
                    needs, ensuring a clean, healthy, and welcoming space for
                    all.
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          <Box
            sx={{
              padding: isMobile ? "20px" : "40px",
              border: "1px solid #E9E9E9",
              borderRadius: "16px",
              boxShadow: "0px 2px 8px -1px #0D0D120A",
              borderColor:
                props?.data?.type === "residential" ? "#32BEAF" : "#E9E9E9",
                cursor:'pointer',
                "&:hover": {
                  border: "1px solid #32BEAF",
                },
            }}
            onClick={() => {
              props?.setData({ ...props?.data, type: "residential" });
              window.scrollTo({top:document.getElementById('service-section').offsetTop-120,behavior:'smooth'});
            }}
          >
            <Grid2
              container
              spacing={2}
              sx={{
                alignItems: "center",
              }}
            >
              <Grid2 size={{ xs: 12, sm: 12, md: 3 }}>
                <Box
                  component={"img"}
                  src={
                    props?.data?.type === "residential"
                      ? "./images/icons/selectedResidential.png"
                      : "./images/icons/unselectedResidential.png"
                  }
                  mr={"32px"}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 12, md: 9 }}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={"#36394A"}
                      fontWeight={600}
                      lineHeight={"24px"}
                    >
                      Residential Services
                    </Typography>
                    <Box
                      component={"img"}
                      src="./images/icons/info.png"
                      ml={"8px"}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    color={"#666D80"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    mt={"12px"}
                  >
                    Top-tier residential cleaning for houses, apartments, and move-ins/outs. We use safe, effective products to keep your home healthy and comfortable.
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
      </Grid2>
      <Box
        mt={"32px"}
      >
        <Box id='service-section'>
      <Step3
        data={props?.data}
        setData={props?.setData}
      />
      </Box>
      </Box>
    </Box>
  );
}
