const handleConversionEvent = () => {
  // let contactEvent = "Call Now - Embedded in Call Button (2024)";
  // let estimateEvent = "Get Estimate - Embedded in Button (2024)";
  // let finalEventText = "";
  // if (eventType == "contact") {
  //   finalEventText = contactEvent;
  // } else if (eventType == "estimate") {
  //   finalEventText = estimateEvent;
  // }
  if (window.gtag && typeof window.gtag === 'function') {
    window.gtag('event', 'conversion', {
      send_to: 'AW-828026734/WirnCKOyj-wZEO7e6ooD',
      value: 10.0,
      currency: 'USD',
      event_callback: () => {
        console.log('Google Analytics event sent successfully.');
      },
    });
    console.log("Google Analytics event sent.");
  } else {
    console.error('Google Tag Manager is not loaded.');
  }
  // if (window.gtag) {
  //   window.gtag("event", finalEventText, {
  //     send_to: "AW-828026734/lSphCKCyj-wZEO7e6ooD",
  //     value: 5,
  //     currency: "USD",
  //     event_callback: () => {
  //       console.log("Google Analytics event sent successfully.");
  //     }
  //   });
  //   console.log("Google Analytics event sent.");
  // } else {
  //   console.error("Google Analytics gtag is not loaded yet.");
  // }
};

const handleGetQuote = () => {
  // const googleFormLink = "https://forms.gle/CBgFZNNPoosgXdXP8";
  // window.open(googleFormLink, "_blank");
  window.location.href = '/getQuote';
};

const initiateCall = (phoneNumber) => {
  if (!phoneNumber) {
    console.error("Phone number is required to make a call.");
    return;
  }

  const telLink = document.createElement("a");
  telLink.href = `tel:${phoneNumber}`;
  telLink.click();
};

const initiateMail = (mailid) => {
  if (!mailid) {
    console.error("mail is required.");
    return;
  }

  const telLink = document.createElement("a");
  telLink.href = `mailto:${mailid}`;
  telLink.click();
};

export { handleConversionEvent, handleGetQuote, initiateCall, initiateMail };
